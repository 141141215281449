const ar = {
  'welcomeScreenHeading': 'أهلاً وسهلاً',
  'requiredCheckboxMessage': 'يرجى تأكيد مربع الاختيار',
  'pleaseSelect': 'يرجى الاختيار...',
  'cancel': 'إلغاء',
  'ok': 'موافق',
  'yes': 'نعم',
  'back': 'رجوع',
  'welcome': 'أهلاً وسهلاً',
  'no': 'لا',
  'pleaseEnter': 'يرجى الإدخال...',
  'thanksForYourCheckin': 'شكراً لتسجيلك.',
  'checkoutConfirmTitle': 'تأكيد تسجيل الخروج',
  'checkoutConfirmMessage': 'يرجى تسجيل الخروج فقط عند انتهاء زيارتك.',
  'yesCheckout': 'تسجيل الخروج الآن',
  'thanksForYourVisit': 'شكراً لزيارتك.',
  'youCanCloseThisPageNow': 'يمكنك الآن إغلاق هذه الصفحة.',
  'checkedOutViewTitle': 'تم تسجيل الخروج',
  'checkoutMessage': 'نقوم بتسجيل خروجك...',
  'checkout': 'تسجيل الخروج',
  'invalidNameFormat': 'يرجى إدخال الاسم الكامل.',
  'clickToSign': 'انقر للتوقيع',
  'invalidQrCodeHeading': 'رمز QR غير صالح',
  'invalidQrCodeText': 'يرجى الاتصال بمكتب الاستقبال.',
  'checkedOut': 'تم تسجيل الخروج',
  'checkinDeniedHeading': 'للأسف، لا يمكننا تسجيل دخولك.',
  'next': 'التالي',
  'groupSelectionHeading': 'اختر مجموعة الزوار:',
  'checkinMessage': 'نقوم بتسجيل دخولك...',
  'aServiceOf': 'خدمة من',
  'checkedIn': 'تم تسجيل الدخول',
  'CheckedInInfo': 'يرجى إبقاء هذه الصفحة مفتوحة لتسجيل الخروج عند انتهاء زيارتك.',
  'checkin': 'تسجيل الدخول',
  'delete': 'حذف',
  'pleaseCheckYourInput': 'يرجى التحقق من الإدخالات الخاصة بك',
  'signAndProceed': 'وقّع للمتابعة',
  'signatureRequiredMessage': 'يرجى التوقيع على المستند',
  'requiredField': 'حقل مطلوب',
  'invalidEmailMessage': 'يرجى إدخال بريد إلكتروني صالح',
  'pleaseEnterANumber': 'يرجى إدخال رقم',
  'pleaseEnterAValidNumber': 'يرجى إدخال عدد صحيح صالح',
  'invalidUrlMessage': 'يرجى إدخال رابط صالح مثل https://www.example.com',
  'functionsError.noSubscriptionFound': 'لا يمكننا تسجيل دخولك حالياً. يرجى الاتصال بمكتب الاستقبال.',
  'checkinLoading': 'جارٍ تسجيل الدخول...',
  'functionsError.denyOnValidationFailed': 'لا يمكننا تسجيل دخولك بناءً على المعلومات المقدمة. يرجى الاتصال بمكتب الاستقبال.',
  'functionsError.missingRequiredInput': 'لا يمكننا تسجيل دخولك لأنك لم تُدخل جميع الحقول المطلوبة. يرجى الاتصال بمكتب الاستقبال.',
  'functionsError.invalidInputData': 'قدمت معلومات غير صحيحة. يرجى الاتصال بمكتب الاستقبال.',
  'functionsError.checkinTokenExpired': 'انتهت صلاحية رابط تسجيل الدخول. يرجى مسح رمز QR مرة أخرى أو الاتصال بمكتب الاستقبال.',
  'startCamera': 'اضغط لتشغيل الكاميرا.',
  'cameraLoading': 'جارٍ التحميل...',
  'photoSectionTitle': 'يرجى التقاط صورة لتسجيل الدخول.',
  'photoSectionDescription': 'يتم استخدام الوصول إلى الكاميرا والصورة فقط للتحقق من هويتك لتسجيل الدخول.',
  'takePhoto': 'التقاط صورة',
  'reTakePhoto': 'إعادة الالتقاط',
  'cameraForbidden': 'تم رفض الوصول إلى الكاميرا.',
  'fullname': 'الاسم الكامل',
  'Details': 'التفاصيل',
  'visitorIdCardWillBePrinted': 'يتم طباعة بطاقة الزائر الخاصة بك ...',
  'visitorIdCardHasBeenPrinted': 'تم طباعة بطاقة الزائر الخاصة بك!',
  'download': 'تنزيل',
  'photoSectionToolbarTitle': 'صور للمشاهدة',
};
  
  export { ar };