const en = {
  'welcomeScreenHeading': 'Welcome!',
  'requiredCheckboxMessage': 'Please confirm the checkbox',
  'pleaseSelect': 'Please select…',
  'cancel': 'Cancel',
  'ok': 'OK',
  'next': 'Next',
  'back': 'Back',
  'yes':'Yes',
  'welcome': 'Welcome',
  'no':'No',
  'checkin': 'Sign in',
  'groupSelectionHeading': 'Select a visitor group:',
  'thanksForYourCheckin': 'Thanks for your sign in.',
  'invalidNameFormat': 'Please enter your full name.',
  'checkinMessage': 'We are signing you in…',
  'checkoutMessage': 'We are signing you out…',
  'delete': 'Delete',
  'aServiceOf': 'A service of',
  'yesCheckout': 'Sign out now',
  'thanksForYourVisit': 'Thanks for your visit.',
  'clickToSign': 'click to sign',
  'youCanCloseThisPageNow': 'You can close this page now.',
  'invalidQrCodeHeading': 'Invalid QR-Code',
  'invalidQrCodeText': 'Please contact the reception.',
  'checkedIn': 'Signed in',
  'checkedOut': 'Signed out',
  'pleaseEnter': 'Please enter…',
  'CheckedInInfo': 'Please keep this page open to sign out after your visit.',
  'pleaseCheckYourInput': 'Please check your input',
  'checkedOutViewTitle': 'You are signed out',
  'checkinDeniedHeading': 'We cannot sign you in',
  'checkout': 'Sign out',
  'checkoutConfirmTitle': 'Sign out confirm',
  'checkoutConfirmMessage': 'Please sign out only after you have finished your visit.',
  'signAndProceed': 'Sign and proceed',
  'signatureRequiredMessage': 'Please sign the document',
  'requiredField': 'Required field',
  'invalidEmailMessage': 'Please enter a valid email address',
  'pleaseEnterANumber': 'Please enter a number',
  'pleaseEnterAValidNumber': 'Please enter a whole number without any special characters',
  'invalidUrlMessage': 'Please enter a valid url e.g. https://www.example.com',
  'functionsError.noSubscriptionFound': 'We cannot sign you in at the moment. Please contact the reception.',
  'checkinLoading': 'Sign in…',
  'functionsError.denyOnValidationFailed': 'We cannot sign you in due to your given information. Please contact the reception.',
  'functionsError.missingRequiredInput': 'We cannot sign you in because you have not filled in all required fields. Please contact the reception.',
  'functionsError.invalidInputData': 'You have made invalid entries. Please contact the reception.',
  'functionsError.checkinTokenExpired': 'Your sign in link has expired. Please scan the QR code again or contact the reception.',
  'startCamera': 'Tap to activate camera.',
  'cameraLoading': 'Loading…',
  'photoSectionTitle': 'Please take a photo of yourself to check in.',
  'photoSectionDescription': 'Access to the camera and the photo will only be used to verify your identity for check-in.',
  'takePhoto': 'Take photo',
  'reTakePhoto': 'Retake photo',
  'cameraForbidden': 'Access to the camera denied.',
  'fullname': 'Fullname',
  'Details': 'Details',
  'visitorIdCardWillBePrinted': 'Your visitor badge is being printed ...',
  'visitorIdCardHasBeenPrinted': 'Your visitor badge has been printed!',
  'download': 'Download',
  'photoSectionToolbarTitle': 'Visitor photo',
};

export { en };
