const lt = {
    'welcomeScreenHeading': 'Sveiki atvykę',
    'requiredCheckboxMessage': 'Prašome pažymėti žymimąjį laukelį',
    'pleaseSelect': 'Pasirinkite…',
    'cancel': 'Atšaukti',
    'ok': 'Gerai',
    'yes': 'Taip',
    'back': 'Atgal',
    'welcome': 'Sveiki',
    'no': 'Ne',
    'pleaseEnter': 'Prašome įvesti…',
    'thanksForYourCheckin': 'Ačiū, kad užsiregistravote.',
    'checkoutConfirmTitle': 'Patvirtinkite išregistravimą',
    'checkoutConfirmMessage': 'Prašome išsiregistruoti tik pasibaigus jūsų apsilankymui.',
    'yesCheckout': 'Dabar išsiregistruoti',
    'thanksForYourVisit': 'Ačiū už jūsų apsilankymą.',
    'youCanCloseThisPageNow': 'Dabar galite uždaryti šį puslapį.',
    'checkedOutViewTitle': 'Jūs išsiregistravote',
    'checkoutMessage': 'Išsiregistruojama…',
    'checkout': 'Išsiregistruoti',
    'invalidNameFormat': 'Prašome nurodyti pilną vardą.',
    'clickToSign': 'spustelėkite, kad pasirašytumėte',
    'invalidQrCodeHeading': 'Netinkamas QR kodas',
    'invalidQrCodeText': 'Prašome kreiptis į registratūrą.',
    'checkedOut': 'Išsiregistravo',
    'checkinDeniedHeading': 'Deja, negalime jūsų užregistruoti.',
    'next': 'Toliau',
    'groupSelectionHeading': 'Pasirinkite lankytojų grupę:',
    'checkinMessage': 'Jus registruojame…',
    'aServiceOf': 'Paslauga teikiama',
    'checkedIn': 'Užregistruota',
    'CheckedInInfo': 'Prašome neperjungti šio puslapio, kad galėtumėte išsiregistruoti po apsilankymo.',
    'checkin': 'Registruotis',
    'delete': 'Ištrinti',
    'pleaseCheckYourInput': 'Patikrinkite savo įvestį',
    'signAndProceed': 'Pasirašykite ir tęskite',
    'signatureRequiredMessage': 'Prašome pasirašyti dokumentą',
    'requiredField': 'Privalomas laukas',
    'invalidEmailMessage': 'Prašome įvesti galiojantį el. pašto adresą',
    'pleaseEnterANumber': 'Prašome įvesti skaičių',
    'pleaseEnterAValidNumber': 'Prašome įvesti galiojantį sveikąjį skaičių',
    'invalidUrlMessage': 'Prašome įvesti galiojantį URL, pvz., https://www.example.com',
    'functionsError.noSubscriptionFound': 'Šiuo metu negalime jūsų užregistruoti. Prašome kreiptis į registratūrą.',
    'checkinLoading': 'Registruojama…',
    'functionsError.denyOnValidationFailed': 'Dėl pateiktų duomenų negalime jūsų užregistruoti. Prašome kreiptis į registratūrą.',
    'functionsError.missingRequiredInput': 'Kadangi neužpildėte visų privalomų laukų, negalime jūsų užregistruoti. Prašome kreiptis į registratūrą.',
    'functionsError.invalidInputData': 'Įvedėte neteisingus duomenis. Prašome kreiptis į registratūrą',
    'functionsError.checkinTokenExpired': 'Jūsų registracijos nuoroda nebegalioja. Prašome dar kartą nuskaityti QR kodą arba kreiptis į registratūrą.',
    'startCamera': 'Palieskite, kad įjungtumėte kamerą.',
    'cameraLoading': 'Įkeliama…',
    'photoSectionTitle': 'Prašome užsiregistruodami nusifotografuoti.',
    'photoSectionDescription': 'Kameros prieiga ir nuotrauka naudojami tik jūsų tapatybei patikrinti registracijos metu.',
    'takePhoto': 'Fotografuoti',
    'reTakePhoto': 'Fotografuoti iš naujo',
    'cameraForbidden': 'Prieiga prie kameros uždrausta.',
    'fullname': 'Pilnas vardas',
    'Details': 'Išsami informacija',
    'visitorIdCardWillBePrinted': 'Jūsų lankytojo pažymėjimas spausdinamas ...',
    'visitorIdCardHasBeenPrinted': 'Jūsų lankytojo pažymėjimas buvo atspausdintas!',
    'download': 'Atsisiuntimas',
    'photoSectionToolbarTitle': 'Lankytojo nuotrauka',
  };
  
  export { lt };