const general = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pt: 'Português',
  jp: '日本語',
  nl: 'Nederlands',
  pl: 'Polski',
  ru: 'Русский',
  tr: 'Türkçe',
  cn: '中文',
  hu: 'Magyar',
  cz: 'Čeština',
  si: 'Slovenščina',
  ua: 'Українська',
  ro: 'Română',
  hr: 'Hrvatski',
  bg: 'Български',
  sk: 'Slovenčina',
  lv: 'Latviešu',
  lt: 'Lietuvių',
  el: 'Ελληνικά',
  ar: 'العربية',
  rs: 'Српски',
};

export { general };