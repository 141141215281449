const es = {
  'welcomeScreenHeading': 'Bienvenido',
  'requiredCheckboxMessage': 'Por favor, confirme la casilla de verificación',
  'pleaseSelect': 'Por favor, seleccione...',
  'cancel': 'Cancelar',
  'ok': 'Aceptar',
  'yes':'Sí',
  'back': 'Volver',
  'welcome': 'Bienvenido',
  'no':'No',
  'pleaseEnter': 'Por favor, introduzca...',
  'thanksForYourCheckin': 'Gracias por su registro.',
  'checkoutConfirmTitle': 'Confirmar salida',
  'checkoutConfirmMessage': 'Por favor, realice el check-out solo cuando haya finalizado su visita.',
  'yesCheckout': 'Salir ahora',
  'thanksForYourVisit': 'Gracias por su visita.',
  'youCanCloseThisPageNow': 'Puede cerrar esta página ahora.',
  'checkedOutViewTitle': 'Ha salido',
  'checkoutMessage': 'Realizando check-out...',
  'checkout': 'Cerrar sesión',
  'invalidNameFormat': 'Por favor, proporcione un nombre completo.',
  'clickToSign': 'haga clic para firmar',
  'invalidQrCodeHeading': 'Código QR no válido',
  'invalidQrCodeText': 'Por favor, póngase en contacto con el personal de recepción.',
  'checkedOut': 'Check-out realizado',
  'checkinDeniedHeading': 'Lo siento, no podemos registrar su entrada en este momento.',
  'next': 'Siguiente',
  'groupSelectionHeading': 'Seleccione un grupo de visitantes:',
  'checkinMessage': 'Realizando registro...',
  'aServiceOf': 'Un servicio de',
  'checkedIn': 'Registrado',
  'CheckedInInfo': 'Por favor, deje esta página abierta para realizar el check-out después de completar su visita.',
  'checkin': 'Registro',
  'delete': 'Eliminar',
  'pleaseCheckYourInput': 'Por favor, verifique su entrada',
  'signAndProceed': 'Firmar y continuar',
  'signatureRequiredMessage': 'Por favor, firme el documento',
  'requiredField': 'Campo obligatorio',
  'invalidEmailMessage': 'Por favor, introduzca una dirección de correo electrónico válida',
  'pleaseEnterANumber': 'Por favor, introduzca un número',
  'pleaseEnterAValidNumber': 'Por favor, introduzca un número entero válido',
  'invalidUrlMessage': 'Por favor, introduzca una URL válida, por ejemplo, https://www.ejemplo.com',
  'functionsError.noSubscriptionFound': 'No podemos registrar su entrada en este momento. Por favor, póngase en contacto con el personal de recepción.',
  'checkinLoading': 'Registrando...',
  'functionsError.denyOnValidationFailed': 'No podemos registrar su entrada debido a la información proporcionada. Por favor, póngase en contacto con el personal de recepción.',
  'functionsError.missingRequiredInput': 'No podemos registrar su entrada debido a que no ha completado todos los campos obligatorios. Por favor, póngase en contacto con el personal de recepción.',
  'functionsError.invalidInputData': 'Ha proporcionado información no válida. Por favor, póngase en contacto con el personal de recepción.',
  'functionsError.checkinTokenExpired': 'Su enlace de registro ha caducado. Escanee el código QR nuevamente o póngase en contacto con el personal de recepción.',
  'startCamera': 'Toque para activar la cámara.',
  'cameraLoading': 'Cargando...',
  'photoSectionTitle': 'Por favor, tome una foto suya para registrarse.',
  'photoSectionDescription': 'El acceso a la cámara y la foto se utilizarán únicamente para verificar su identidad durante el registro.',
  'takePhoto': 'Tomar foto',
  'reTakePhoto': 'Volver a tomar foto',
  'cameraForbidden': 'Acceso a la cámara denegado.',
  'fullname': 'Nombre completo',
  'Details': 'Detalles',
  'visitorIdCardWillBePrinted': 'Su credencial de visitante se está imprimiendo ...',
  'visitorIdCardHasBeenPrinted': 'Su credencial de visitante ha sido impresa!',
  'download': 'Descarga',
  'photoSectionToolbarTitle': 'Foto de visitante',
};

export { es };