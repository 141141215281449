const rs = {
  'welcomeScreenHeading': 'Dobrodošli',
  'requiredCheckboxMessage': 'Molimo potvrdite izbor u polju za potvrdu',
  'pleaseSelect': 'Molimo izaberite…',
  'cancel': 'Otkaži',
  'ok': 'OK',
  'yes': 'Da',
  'back': 'Nazad',
  'welcome': 'Dobrodošli',
  'no': 'Ne',
  'pleaseEnter': 'Molimo unesite…',
  'thanksForYourCheckin': 'Hvala vam na prijavi.',
  'checkoutConfirmTitle': 'Potvrda odjave',
  'checkoutConfirmMessage': 'Molimo odjavite se tek kada završite svoju posetu.',
  'yesCheckout': 'Odjavi se sada',
  'thanksForYourVisit': 'Hvala vam na poseti.',
  'youCanCloseThisPageNow': 'Sada možete zatvoriti ovu stranicu.',
  'checkedOutViewTitle': 'Odjavljeni ste',
  'checkoutMessage': 'Odjavljujemo vas…',
  'checkout': 'Odjava',
  'invalidNameFormat': 'Molimo unesite puno ime.',
  'clickToSign': 'Kliknite da biste potpisali',
  'invalidQrCodeHeading': 'Nevažeći QR kod',
  'invalidQrCodeText': 'Molimo obratite se osoblju na prijemu.',
  'checkedOut': 'Odjavljen',
  'checkinDeniedHeading': 'Nažalost, ne možemo vas prijaviti.',
  'next': 'Dalje',
  'groupSelectionHeading': 'Izaberite grupu posetilaca:',
  'checkinMessage': 'Prijavljujemo vas…',
  'aServiceOf': 'Usluga od',
  'checkedIn': 'Prijavljen',
  'CheckedInInfo': 'Molimo ostavite ovu stranicu otvorenom kako biste se odjavili nakon završetka posete.',
  'checkin': 'Prijava',
  'delete': 'Obriši',
  'pleaseCheckYourInput': 'Molimo proverite svoje unose',
  'signAndProceed': 'Potpišite i nastavite',
  'signatureRequiredMessage': 'Molimo potpišite dokument',
  'requiredField': 'Obavezno polje',
  'invalidEmailMessage': 'Molimo unesite važeću e-mail adresu',
  'pleaseEnterANumber': 'Molimo unesite broj',
  'pleaseEnterAValidNumber': 'Molimo unesite važeći ceo broj',
  'invalidUrlMessage': 'Molimo unesite važeću URL adresu, npr. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Trenutno vas ne možemo prijaviti. Molimo obratite se osoblju na prijemu.',
  'checkinLoading': 'Prijavljivanje…',
  'functionsError.denyOnValidationFailed': 'Ne možemo vas prijaviti na osnovu unetih podataka. Molimo obratite se osoblju na prijemu.',
  'functionsError.missingRequiredInput': 'Niste popunili sva obavezna polja, zbog čega ne možemo izvršiti prijavu. Molimo obratite se osoblju na prijemu.',
  'functionsError.invalidInputData': 'Uneli ste nevažeće podatke. Molimo obratite se osoblju na prijemu.',
  'functionsError.checkinTokenExpired': 'Vaš link za prijavu je istekao. Molimo ponovo skenirajte QR kod ili se obratite osoblju na prijemu.',
  'startCamera': 'Dodirnite za aktiviranje kamere.',
  'cameraLoading': 'Učitavanje…',
  'photoSectionTitle': 'Molimo napravite svoju fotografiju za prijavu.',
  'photoSectionDescription': 'Pristup kameri i fotografija koriste se isključivo za potvrdu vašeg identiteta prilikom prijave.',
  'takePhoto': 'Napravite fotografiju',
  'reTakePhoto': 'Ponovite fotografiju',
  'cameraForbidden': 'Pristup kameri je odbijen.',
  'fullname': 'Puno ime',
  'Details': 'Detalji',
  'visitorIdCardWillBePrinted': 'Vaša identifikaciona kartica za posetioce se štampa…',
  'visitorIdCardHasBeenPrinted': 'Vaša identifikaciona kartica za posetioce je odštampana!',
  'download': 'Preuzmi',
  'photoSectionToolbarTitle': 'Fotografija posetioca',
};
  
  export { rs };