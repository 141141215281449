const ru = {
  'welcomeScreenHeading': 'Добро пожаловать',
  'requiredCheckboxMessage': 'Пожалуйста, подтвердите флажок',
  'pleaseSelect': 'Пожалуйста, выберите…',
  'cancel': 'Отмена',
  'ok': 'OK',
  'yes':'Да',
  'back': 'Назад',
  'welcome': 'Добро пожаловать',
  'no':'Нет',
  'pleaseEnter': 'Пожалуйста, введите…',
  'thanksForYourCheckin': 'Спасибо за вашу регистрацию.',
  'checkoutConfirmTitle': 'Подтверждение выхода',
  'checkoutConfirmMessage': 'Пожалуйста, выйдите, когда закончите свой визит.',
  'yesCheckout': 'Выйти сейчас',
  'thanksForYourVisit': 'Спасибо за ваш визит.',
  'youCanCloseThisPageNow': 'Теперь вы можете закрыть эту страницу.',
  'checkedOutViewTitle': 'Вы вышли',
  'checkoutMessage': 'Мы выходим…',
  'checkout': 'Выйти',
  'invalidNameFormat': 'Пожалуйста, укажите полное имя.',
  'clickToSign': 'нажмите, чтобы подписать',
  'invalidQrCodeHeading': 'Недействительный QR-код',
  'invalidQrCodeText': 'Пожалуйста, обратитесь к персоналу ресепшн.',
  'checkedOut': 'Вышли',
  'checkinDeniedHeading': 'К сожалению, мы не можем вас зарегистрировать.',
  'next': 'Далее',
  'groupSelectionHeading': 'Выберите группу посетителей:',
  'checkinMessage': 'Мы регистрируем вас…',
  'aServiceOf': 'Служба',
  'checkedIn': 'Зарегистрирован',
  'CheckedInInfo': 'Пожалуйста, оставьте эту страницу открытой, чтобы выйти после завершения визита.',
  'checkin': 'Регистрация',
  'delete': 'Удалить',
  'pleaseCheckYourInput': 'Пожалуйста, проверьте введенные данные',
  'signAndProceed': 'Подписать и продолжить',
  'signatureRequiredMessage': 'Пожалуйста, подпишите документ',
  'requiredField': 'Обязательное поле',
  'invalidEmailMessage': 'Пожалуйста, введите действительный адрес электронной почты',
  'pleaseEnterANumber': 'Пожалуйста, введите число',
  'pleaseEnterAValidNumber': 'Пожалуйста, введите целое число',
  'invalidUrlMessage': 'Пожалуйста, введите действительный URL, например, https://www.example.com',
  'functionsError.noSubscriptionFound': 'Мы не можем зарегистрировать вас в данный момент. Пожалуйста, обратитесь к персоналу ресепшн.',
  'checkinLoading': 'Регистрация…',
  'functionsError.denyOnValidationFailed': 'Мы не можем зарегистрировать вас из-за ваших данных. Пожалуйста, обратитесь к персоналу ресепшн.',
  'functionsError.missingRequiredInput': 'Мы не можем зарегистрировать вас, так как вы не заполнили все обязательные поля. Пожалуйста, обратитесь к персоналу ресепшн.',
  'functionsError.invalidInputData': 'Вы ввели недействительные данные. Пожалуйста, обратитесь к персоналу ресепшн.',
  'functionsError.checkinTokenExpired': 'Ваша ссылка на регистрацию истекла. Пожалуйста, отсканируйте QR-код еще раз или обратитесь к персоналу ресепшн.',
  'startCamera': 'Нажмите, чтобы включить камеру.',
  'cameraLoading': 'Загрузка…',
  'photoSectionTitle': 'Пожалуйста, сделайте фотографию для регистрации.',
  'photoSectionDescription': 'Доступ к камере и фотографии используется исключительно для проверки вашей личности для регистрации.',
  'takePhoto': 'Сделать фото',
  'reTakePhoto': 'Переснять',
  'cameraForbidden': 'Доступ к камере запрещен.',
  'fullname': 'Полное имя',
  'Details': 'Подробности',
  'visitorIdCardWillBePrinted': 'Ваш пропуск посетителя печатается ...',
  'visitorIdCardHasBeenPrinted': 'Ваш пропуск посетителя напечатан!',
  'download': 'Загрузка',
  'photoSectionToolbarTitle': 'Фото посетителя',
};

export { ru };
