const bg = {
  'welcomeScreenHeading': 'Добре дошли',
  'requiredCheckboxMessage': 'Моля, потвърдете отметката',
  'pleaseSelect': 'Моля, изберете…',
  'cancel': 'Отказ',
  'ok': 'ОК',
  'yes': 'Да',
  'back': 'Назад',
  'welcome': 'Добре дошли',
  'no': 'Не',
  'pleaseEnter': 'Моля, въведете…',
  'thanksForYourCheckin': 'Благодарим за вашата регистрация.',
  'checkoutConfirmTitle': 'Потвърдете отписването',
  'checkoutConfirmMessage': 'Моля, отпишете се, само ако сте приключили с посещението си.',
  'yesCheckout': 'Отписване сега',
  'thanksForYourVisit': 'Благодарим за вашето посещение.',
  'youCanCloseThisPageNow': 'Можете да затворите тази страница.',
  'checkedOutViewTitle': 'Вие сте отписани',
  'checkoutMessage': 'Отписваме ви…',
  'checkout': 'Отписване',
  'invalidNameFormat': 'Моля, въведете пълно име.',
  'clickToSign': 'кликнете, за да подпишете',
  'invalidQrCodeHeading': 'Невалиден QR код',
  'invalidQrCodeText': 'Моля, свържете се с рецепцията.',
  'checkedOut': 'Отписани',
  'checkinDeniedHeading': 'Не можем да ви регистрираме.',
  'next': 'Напред',
  'groupSelectionHeading': 'Изберете група посетители:',
  'checkinMessage': 'Регистрираме ви…',
  'aServiceOf': 'Услуга от',
  'checkedIn': 'Регистрирани',
  'CheckedInInfo': 'Моля, оставете тази страница отворена, за да се отпишете след приключване на посещението си.',
  'checkin': 'Регистрация',
  'delete': 'Изтриване',
  'pleaseCheckYourInput': 'Моля, проверете въведените данни',
  'signAndProceed': 'Подпишете и продължете',
  'signatureRequiredMessage': 'Моля, подпишете документа',
  'requiredField': 'Задължително поле',
  'invalidEmailMessage': 'Моля, въведете валиден имейл адрес',
  'pleaseEnterANumber': 'Моля, въведете число',
  'pleaseEnterAValidNumber': 'Моля, въведете валидно цяло число',
  'invalidUrlMessage': 'Моля, въведете валиден URL, напр. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Не можем да ви регистрираме в момента. Моля, свържете се с рецепцията.',
  'checkinLoading': 'Регистриране…',
  'functionsError.denyOnValidationFailed': 'Не можем да ви регистрираме поради предоставената информация. Моля, свържете се с рецепцията.',
  'functionsError.missingRequiredInput': 'Тъй като не сте попълнили всички задължителни полета, не можем да ви регистрираме. Моля, свържете се с рецепцията.',
  'functionsError.invalidInputData': 'Предоставили сте невалидна информация. Моля, свържете се с рецепцията.',
  'functionsError.checkinTokenExpired': 'Линкът за регистрация е изтекъл. Моля, сканирайте QR кода отново или се свържете с рецепцията.',
  'startCamera': 'Докоснете, за да активирате камерата.',
  'cameraLoading': 'Зарежда се…',
  'photoSectionTitle': 'Моля, направете своя снимка за регистрация.',
  'photoSectionDescription': 'Достъпът до камерата и снимката се използват само за потвърждение на вашата самоличност за регистрация.',
  'takePhoto': 'Направете снимка',
  'reTakePhoto': 'Направете отново',
  'cameraForbidden': 'Достъпът до камерата е отказан.',
  'fullname': 'Пълно име',
  'Details': 'Детайли',
  'visitorIdCardWillBePrinted': 'Вашият пропуск за посетители се отпечатва ...',
  'visitorIdCardHasBeenPrinted': 'Вашият пропуск за посетители е отпечатан!',
  'download': 'Изтегляне',
  'photoSectionToolbarTitle': 'Снимка на посетител',
}; 
export { bg };