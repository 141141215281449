import type { ITimeSetting } from '@einfachgast/shared';

 /* NOTE:
  Hier ist ein "mapping" implementiert, um das Attribute value auf das (korrekte) Attribut amount zu mappen.
  Das ist notwendig, da bei der Erst-Implementierung dieses Features noch value verwendet wurde, aber "amount"
  korrekt ist.
  Dadurch wird nach und nach die Umstellung auf "amount" vollzogen, ohne dass es zu Problemen kommt.
  @Todo 2027 nochmal prüfen, ob es noch irgendwo Kunden(VisitForms) mit dem Attribute "value" gibt.
  */ 
export const timeSettingMap = (oldTimeSetting: any): ITimeSetting => {
  return oldTimeSetting && oldTimeSetting['value']
        ? { unit: oldTimeSetting.unit, amount: (oldTimeSetting as any)['value'] }
        : oldTimeSetting;
};