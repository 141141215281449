const sk = {
  'welcomeScreenHeading': 'Vitajte',
  'requiredCheckboxMessage': 'Potvrďte zaškrtávacie políčko',
  'pleaseSelect': 'Prosím, vyberte…',
  'cancel': 'Zrušiť',
  'ok': 'OK',
  'yes': 'Áno',
  'back': 'Späť',
  'welcome': 'Vitajte',
  'no': 'Nie',
  'pleaseEnter': 'Zadajte…',
  'thanksForYourCheckin': 'Ďakujeme za vašu registráciu.',
  'checkoutConfirmTitle': 'Potvrdenie odhlásenia',
  'checkoutConfirmMessage': 'Odhláste sa, až keď ukončíte návštevu.',
  'yesCheckout': 'Odhlásiť sa teraz',
  'thanksForYourVisit': 'Ďakujeme za vašu návštevu.',
  'youCanCloseThisPageNow': 'Teraz môžete túto stránku zavrieť.',
  'checkedOutViewTitle': 'Ste odhlásení',
  'checkoutMessage': 'Odhlasujeme vás…',
  'checkout': 'Odhlásiť',
  'invalidNameFormat': 'Uveďte celé meno.',
  'clickToSign': 'kliknite na podpísanie',
  'invalidQrCodeHeading': 'Neplatný QR kód',
  'invalidQrCodeText': 'Obráťte sa na personál na recepcii.',
  'checkedOut': 'Odhlásený',
  'checkinDeniedHeading': 'Nemôžeme vás prihlásiť.',
  'next': 'Ďalej',
  'groupSelectionHeading': 'Vyberte skupinu návštevníkov:',
  'checkinMessage': 'Prihlasujeme vás…',
  'aServiceOf': 'Služba poskytovaná',
  'checkedIn': 'Prihlásený',
  'CheckedInInfo': 'Nezatvárajte túto stránku, aby ste sa mohli po ukončení návštevy odhlásiť.',
  'checkin': 'Prihlásiť',
  'delete': 'Vymazať',
  'pleaseCheckYourInput': 'Skontrolujte svoje údaje',
  'signAndProceed': 'Podpísať a pokračovať',
  'signatureRequiredMessage': 'Podpíšte dokument',
  'requiredField': 'Povinné pole',
  'invalidEmailMessage': 'Zadajte platnú e-mailovú adresu',
  'pleaseEnterANumber': 'Zadajte číslo',
  'pleaseEnterAValidNumber': 'Zadajte platné celé číslo',
  'invalidUrlMessage': 'Zadajte platnú URL, napr. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Momentálne vás nemôžeme prihlásiť. Obráťte sa na personál na recepcii.',
  'checkinLoading': 'Prihlasujeme…',
  'functionsError.denyOnValidationFailed': 'Na základe vašich údajov vás nemôžeme prihlásiť. Obráťte sa na personál na recepcii.',
  'functionsError.missingRequiredInput': 'Nezadali ste všetky povinné údaje. Obráťte sa na personál na recepcii.',
  'functionsError.invalidInputData': 'Zadali ste neplatné údaje. Obráťte sa na personál na recepcii.',
  'functionsError.checkinTokenExpired': 'Váš prihlasovací odkaz vypršal. Naskenujte QR kód znova alebo sa obráťte na personál na recepcii.',
  'startCamera': 'Klepnite pre aktiváciu kamery.',
  'cameraLoading': 'Načítava sa…',
  'photoSectionTitle': 'Pre prihlásenie sa odfoťte.',
  'photoSectionDescription': 'Prístup ku kamere a fotografia sa používajú výlučne na overenie vašej identity.',
  'takePhoto': 'Odfoťte sa',
  'reTakePhoto': 'Odfotiť znova',
  'cameraForbidden': 'Prístup ku kamere bol zamietnutý.',
  'fullname': 'Celé meno',
  'Details': 'Podrobnosti',
  'visitorIdCardWillBePrinted': 'Váš návštevnícky preukaz sa tlačí ...',
  'visitorIdCardHasBeenPrinted': 'Váš návštevnícky preukaz bol vytlačený!',
  'download': 'Sťahovanie',
  'photoSectionToolbarTitle': 'Fotografia návštevníka',
};

export { sk };