const tr = {
  'welcomeScreenHeading': 'Hoş geldiniz',
  'requiredCheckboxMessage': 'Lütfen onay kutusunu işaretleyin',
  'pleaseSelect': 'Lütfen seçiniz...',
  'cancel': 'İptal',
  'ok': 'Tamam',
  'yes': 'Evet',
  'back': 'Geri',
  'welcome': 'Hoş geldiniz',
  'no': 'Hayır',
  'pleaseEnter': 'Lütfen giriniz...',
  'thanksForYourCheckin': 'Kaydınız için teşekkürler.',
  'checkoutConfirmTitle': 'Çıkış yapmayı onayla',
  'checkoutConfirmMessage': 'Lütfen ziyaretinizi tamamladıktan sonra çıkış yapın.',
  'yesCheckout': 'Şimdi çıkış yap',
  'thanksForYourVisit': 'Ziyaretiniz için teşekkürler.',
  'youCanCloseThisPageNow': 'Şimdi bu sayfayı kapatabilirsiniz.',
  'checkedOutViewTitle': 'Çıkış yaptınız',
  'checkoutMessage': 'Çıkış yapılıyor...',
  'checkout': 'Çıkış yapmak',
  'invalidNameFormat': 'Lütfen geçerli bir isim girin.',
  'clickToSign': 'İmzalamak için tıklayın',
  'invalidQrCodeHeading': 'Geçersiz QR Kodu',
  'invalidQrCodeText': 'Lütfen resepsiyon görevlisine başvurun.',
  'checkedOut': 'Çıkış yapıldı',
  'checkinDeniedHeading': 'Üzgünüz, sizi kaydedemiyoruz.',
  'next': 'İleri',
  'groupSelectionHeading': 'Ziyaretçi grubunu seçin:',
  'checkinMessage': 'Kayıt yapılıyor...',
  'aServiceOf': 'Bir hizmeti',
  'checkedIn': 'Kayıt yapıldı',
  'CheckedInInfo': 'Ziyaretinizi tamamladıktan sonra çıkış yapmak için bu sayfayı açık bırakın lütfen.',
  'checkin': 'Kayıt yap',
  'delete': 'Sil',
  'pleaseCheckYourInput': 'Lütfen girdilerinizi kontrol edin',
  'signAndProceed': 'İmzala ve devam et',
  'signatureRequiredMessage': 'Lütfen belgeyi imzalayın',
  'requiredField': 'Zorunlu alan',
  'invalidEmailMessage': 'Lütfen geçerli bir e-posta adresi girin',
  'pleaseEnterANumber': 'Lütfen bir sayı girin',
  'pleaseEnterAValidNumber': 'Lütfen geçerli bir tam sayı girin',
  'invalidUrlMessage': 'Lütfen geçerli bir URL girin örn. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Şu anda sizi kaydedemiyoruz. Lütfen resepsiyon görevlisine başvurun.',
  'checkinLoading': 'Kaydediliyor...',
  'functionsError.denyOnValidationFailed': 'Bilgilerinizin doğrulanamaması nedeniyle sizi kaydedemiyoruz. Lütfen resepsiyon görevlisine başvurun.',
  'functionsError.missingRequiredInput': 'Zorunlu alanları doldurmadığınız için sizi kaydedemiyoruz. Lütfen resepsiyon görevlisine başvurun.',
  'functionsError.invalidInputData': 'Geçersiz girdi yaptınız. Lütfen resepsiyon görevlisine başvurun.',
  'functionsError.checkinTokenExpired': 'Kayıt bağlantınız süresi doldu. Lütfen QR kodunu tekrar tarayın veya resepsiyon görevlisine başvurun.',
  'startCamera': 'Kamerayı açmak için dokunun.',
  'cameraLoading': 'Yükleniyor...',
  'photoSectionTitle': 'Lütfen kayıt için fotoğrafınızı çekin.',
  'photoSectionDescription': 'Kimliğinizi doğrulamak için kameraya ve fotoğrafa sadece kayıt için kullanılacaktır.',
  'takePhoto': 'Fotoğraf çek',
  'reTakePhoto': 'Yeniden çek',
  'cameraForbidden': 'Kamera erişimi reddedildi.',
  'fullname': 'Tam Ad',
  'Details': 'Detaylar',
  'visitorIdCardWillBePrinted': 'Ziyaretçi kartınız yazdırılıyor ...',
  'visitorIdCardHasBeenPrinted': 'Ziyaretçi kartınız basıldı!',
  'download': 'İndirme',
  'photoSectionToolbarTitle': 'Ziyaretçi fotoğrafı',
};

export { tr };
