const fr = {
  'welcomeScreenHeading': 'Bienvenue',
  'requiredCheckboxMessage': 'Veuillez cocher la case obligatoire',
  'pleaseSelect': 'Veuillez sélectionner…',
  'cancel': 'Annuler',
  'ok': 'OK',
  'yes': 'Oui',
  'back': 'Retour',
  'welcome': 'Bienvenue',
  'no': 'Non',
  'pleaseEnter': 'Veuillez saisir…',
  'thanksForYourCheckin': 'Merci de votre enregistrement.',
  'checkoutConfirmTitle': 'Confirmer la déconnexion',
  'checkoutConfirmMessage': 'Veuillez vous déconnecter une fois votre visite terminée.',
  'yesCheckout': 'Déconnexion maintenant',
  'thanksForYourVisit': 'Merci de votre visite.',
  'youCanCloseThisPageNow': 'Vous pouvez maintenant fermer cette page.',
  'checkedOutViewTitle': 'Vous êtes déconnecté',
  'checkoutMessage': 'Nous vous déconnectons…',
  'checkout': 'Se déconnecter',
  'invalidNameFormat': 'Veuillez fournir un nom complet.',
  'clickToSign': 'cliquez pour signer',
  'invalidQrCodeHeading': 'QR Code invalide',
  'invalidQrCodeText': 'Veuillez contacter le personnel d’accueil.',
  'checkedOut': 'Déconnecté',
  'checkinDeniedHeading': 'Nous ne pouvons malheureusement pas vous enregistrer.',
  'next': 'Suivant',
  'groupSelectionHeading': 'Sélectionnez un groupe de visiteurs :',
  'checkinMessage': 'Nous vous enregistrons…',
  'aServiceOf': 'Un service de',
  'checkedIn': 'Enregistré',
  'CheckedInInfo': 'Veuillez laisser cette page ouverte pour vous déconnecter après votre visite.',
  'checkin': 'Enregistrement',
  'delete': 'Supprimer',
  'pleaseCheckYourInput': 'Veuillez vérifier vos informations',
  'signAndProceed': 'Signer et continuer',
  'signatureRequiredMessage': 'Veuillez signer le document',
  'requiredField': 'Champ obligatoire',
  'invalidEmailMessage': 'Veuillez saisir une adresse e-mail valide',
  'pleaseEnterANumber': 'Veuillez saisir un nombre',
  'pleaseEnterAValidNumber': 'Veuillez saisir un nombre entier valide',
  'invalidUrlMessage': 'Veuillez saisir une URL valide, par exemple https://www.example.com',
  'functionsError.noSubscriptionFound': 'Nous ne pouvons pas vous enregistrer pour le moment. Veuillez contacter le personnel d’accueil.',
  'checkinLoading': 'Enregistrement en cours…',
  'functionsError.denyOnValidationFailed': 'Nous ne pouvons pas vous enregistrer en raison de vos informations. Veuillez contacter le personnel d’accueil.',
  'functionsError.missingRequiredInput': 'Étant donné que vous n’avez pas rempli tous les champs obligatoires, nous ne pouvons pas vous enregistrer. Veuillez contacter le personnel d’accueil.',
  'functionsError.invalidInputData': 'Vous avez fourni des informations non valides. Veuillez contacter le personnel d’accueil.',
  'functionsError.checkinTokenExpired': 'Votre lien d’enregistrement a expiré. Veuillez scanner à nouveau le QR Code ou contacter le personnel d’accueil.',
  'startCamera': 'Appuyez pour activer la caméra.',
  'cameraLoading': 'Chargement…',
  'photoSectionTitle': 'Veuillez prendre une photo de vous pour vous enregistrer.',
  'photoSectionDescription': 'L’accès à la caméra et à la photo est utilisé uniquement pour vérifier votre identité lors de l’enregistrement.',
  'takePhoto': 'Prendre une photo',
  'reTakePhoto': 'Reprendre la photo',
  'cameraForbidden': 'Accès à la caméra refusé.',
  'fullname': 'Nom complet',
  'Details': 'Détails',
  'visitorIdCardWillBePrinted': 'Votre badge visiteur est en cours d\'impression ...',
  'visitorIdCardHasBeenPrinted': 'Votre badge visiteur a été imprimé!',
  'download': 'Téléchargement',
  'photoSectionToolbarTitle': 'Photo du visiteur',
};

export { fr };