const el = {
  'welcomeScreenHeading': 'Καλώς ήρθατε',
  'requiredCheckboxMessage': 'Παρακαλώ επιβεβαιώστε το πλαίσιο επιλογής',
  'pleaseSelect': 'Παρακαλώ επιλέξτε…',
  'cancel': 'Ακύρωση',
  'ok': 'Εντάξει',
  'yes': 'Ναι',
  'back': 'Πίσω',
  'welcome': 'Καλώς ήρθατε',
  'no': 'Όχι',
  'pleaseEnter': 'Παρακαλώ εισάγετε…',
  'thanksForYourCheckin': 'Ευχαριστούμε για την εγγραφή σας.',
  'checkoutConfirmTitle': 'Επιβεβαίωση αποχώρησης',
  'checkoutConfirmMessage': 'Παρακαλώ αποχωρήστε μόνο όταν έχετε ολοκληρώσει την επίσκεψή σας.',
  'yesCheckout': 'Αποχώρηση τώρα',
  'thanksForYourVisit': 'Ευχαριστούμε για την επίσκεψή σας.',
  'youCanCloseThisPageNow': 'Μπορείτε να κλείσετε αυτή τη σελίδα τώρα.',
  'checkedOutViewTitle': 'Έχετε αποχωρήσει',
  'checkoutMessage': 'Αποχωρείτε…',
  'checkout': 'Αποχώρηση',
  'invalidNameFormat': 'Παρακαλώ εισάγετε πλήρες όνομα.',
  'clickToSign': 'Κάντε κλικ για υπογραφή',
  'invalidQrCodeHeading': 'Μη έγκυρος κωδικός QR',
  'invalidQrCodeText': 'Παρακαλώ επικοινωνήστε με το προσωπικό υποδοχής.',
  'checkedOut': 'Αποχωρήσατε',
  'checkinDeniedHeading': 'Δεν μπορούμε να σας εγγράψουμε.',
  'next': 'Επόμενο',
  'groupSelectionHeading': 'Επιλέξτε ομάδα επισκεπτών:',
  'checkinMessage': 'Γίνεται εγγραφή…',
  'aServiceOf': 'Μια υπηρεσία από',
  'checkedIn': 'Έχετε εγγραφεί',
  'CheckedInInfo': 'Παρακαλώ αφήστε αυτή τη σελίδα ανοιχτή για να αποχωρήσετε όταν ολοκληρώσετε την επίσκεψή σας.',
  'checkin': 'Εγγραφή',
  'delete': 'Διαγραφή',
  'pleaseCheckYourInput': 'Παρακαλώ ελέγξτε τα δεδομένα σας',
  'signAndProceed': 'Υπογράψτε και συνεχίστε',
  'signatureRequiredMessage': 'Παρακαλώ υπογράψτε το έγγραφο',
  'requiredField': 'Απαιτούμενο πεδίο',
  'invalidEmailMessage': 'Παρακαλώ εισάγετε έγκυρη διεύθυνση email',
  'pleaseEnterANumber': 'Παρακαλώ εισάγετε έναν αριθμό',
  'pleaseEnterAValidNumber': 'Παρακαλώ εισάγετε έναν έγκυρο ακέραιο αριθμό',
  'invalidUrlMessage': 'Παρακαλώ εισάγετε ένα έγκυρο URL π.χ. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Δεν μπορούμε να σας εγγράψουμε αυτή τη στιγμή. Παρακαλώ επικοινωνήστε με το προσωπικό υποδοχής.',
  'checkinLoading': 'Γίνεται εγγραφή…',
  'functionsError.denyOnValidationFailed': 'Δεν μπορούμε να σας εγγράψουμε λόγω των στοιχείων σας. Παρακαλώ επικοινωνήστε με το προσωπικό υποδοχής.',
  'functionsError.missingRequiredInput': 'Δεν έχετε συμπληρώσει όλα τα απαιτούμενα πεδία. Παρακαλώ επικοινωνήστε με το προσωπικό υποδοχής.',
  'functionsError.invalidInputData': 'Έχετε εισάγει μη έγκυρα δεδομένα. Παρακαλώ επικοινωνήστε με το προσωπικό υποδοχής',
  'functionsError.checkinTokenExpired': 'Ο σύνδεσμος εγγραφής σας έχει λήξει. Σαρώστε ξανά τον QR κωδικό ή επικοινωνήστε με το προσωπικό υποδοχής.',
  'startCamera': 'Πατήστε για ενεργοποίηση κάμερας.',
  'cameraLoading': 'Φόρτωση…',
  'photoSectionTitle': 'Παρακαλώ τραβήξτε μια φωτογραφία για την εγγραφή σας.',
  'photoSectionDescription': 'Η πρόσβαση στην κάμερα και η φωτογραφία χρησιμοποιούνται αποκλειστικά για την ταυτοποίηση σας.',
  'takePhoto': 'Τραβήξτε φωτογραφία',
  'reTakePhoto': 'Τραβήξτε ξανά',
  'cameraForbidden': 'Η πρόσβαση στην κάμερα απορρίφθηκε.',
  'fullname': 'Πλήρες όνομα',
  'Details': 'Λεπτομέρειες',
  'visitorIdCardWillBePrinted': 'Η κάρτα επισκέπτη σας εκτυπώνεται ...',
  'visitorIdCardHasBeenPrinted': 'Η κάρτα επισκέπτη σας εκτυπώθηκε!',
  'download': 'Λήψη',
  'photoSectionToolbarTitle': 'Φωτογραφία επισκέπτη',
};
  
export { el };