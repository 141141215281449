const pt = {
  'welcomeScreenHeading': 'Bem-vindo',
  'requiredCheckboxMessage': 'Por favor, confirme a caixa de seleção',
  'pleaseSelect': 'Por favor, selecione...',
  'cancel': 'Cancelar',
  'ok': 'OK',
  'yes':'Sim',
  'back': 'Voltar',
  'welcome': 'Bem-vindo',
  'no':'Não',
  'pleaseEnter': 'Por favor, insira...',
  'thanksForYourCheckin': 'Obrigado pelo seu check-in.',
  'checkoutConfirmTitle': 'Confirmar logout',
  'checkoutConfirmMessage': 'Por favor, faça logout apenas quando terminar sua visita.',
  'yesCheckout': 'Fazer logout agora',
  'thanksForYourVisit': 'Obrigado pela sua visita.',
  'youCanCloseThisPageNow': 'Você pode fechar esta página agora.',
  'checkedOutViewTitle': 'Você fez logout',
  'checkoutMessage': 'Fazendo logout...',
  'checkout': 'Desconectar',
  'invalidNameFormat': 'Por favor, forneça um nome completo válido.',
  'clickToSign': 'clique para assinar',
  'invalidQrCodeHeading': 'Código QR inválido',
  'invalidQrCodeText': 'Por favor, entre em contato com a equipe de recepção.',
  'checkedOut': 'Logout realizado',
  'checkinDeniedHeading': 'Infelizmente, não podemos registrar sua entrada.',
  'next': 'Próximo',
  'groupSelectionHeading': 'Selecione um grupo de visitantes:',
  'checkinMessage': 'Registrando sua entrada...',
  'aServiceOf': 'Um serviço de',
  'checkedIn': 'Entrada realizada',
  'CheckedInInfo': 'Por favor, mantenha esta página aberta para fazer logout após concluir sua visita.',
  'checkin': 'Check-in',
  'delete': 'Excluir',
  'pleaseCheckYourInput': 'Por favor, verifique suas entradas',
  'signAndProceed': 'Assinar e prosseguir',
  'signatureRequiredMessage': 'Por favor, assine o documento',
  'requiredField': 'Campo obrigatório',
  'invalidEmailMessage': 'Por favor, insira um endereço de e-mail válido',
  'pleaseEnterANumber': 'Por favor, insira um número',
  'pleaseEnterAValidNumber': 'Por favor, insira um número inteiro válido',
  'invalidUrlMessage': 'Por favor, insira uma URL válida, por exemplo https://www.example.com',
  'functionsError.noSubscriptionFound': 'Atualmente não podemos registrar sua entrada. Por favor, entre em contato com a equipe de recepção.',
  'checkinLoading': 'Registrando entrada...',
  'functionsError.denyOnValidationFailed': 'Não podemos registrar sua entrada devido às informações fornecidas. Por favor, entre em contato com a equipe de recepção.',
  'functionsError.missingRequiredInput': 'Não podemos registrar sua entrada porque você não preencheu todos os campos obrigatórios. Por favor, entre em contato com a equipe de recepção.',
  'functionsError.invalidInputData': 'Você forneceu informações inválidas. Por favor, entre em contato com a equipe de recepção.',
  'functionsError.checkinTokenExpired': 'Seu link de check-in expirou. Por favor, escaneie o código QR novamente ou entre em contato com a equipe de recepção.',
  'startCamera': 'Toque para ativar a câmera.',
  'cameraLoading': 'Carregando...',
  'photoSectionTitle': 'Por favor, tire uma foto de si mesmo para o check-in.',
  'photoSectionDescription': 'O acesso à câmera e a foto serão usados exclusivamente para verificar sua identidade durante o check-in.',
  'takePhoto': 'Tirar foto',
  'reTakePhoto': 'Tirar foto novamente',
  'cameraForbidden': 'Acesso à câmera negado.',
  'fullname': 'Nome completo',
  'Details': 'Detalhes',
  'visitorIdCardWillBePrinted': 'O seu crachá de visitante está sendo impresso ...',
  'visitorIdCardHasBeenPrinted': 'O seu crachá de visitante foi impresso!',
  'download': 'Download',
  'photoSectionToolbarTitle': 'Foto do visitante',
};

export { pt };
